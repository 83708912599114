<!--转账111-->
<template>
  <div>
    <el-row style="margin-left: 10%;margin-top: 1%;size: B4">
      <el-col :span="11">
        <el-form label-width="100px" style="margin-top: 10px;margin-left: 10px"
                 :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm">

          <el-row>
            <el-col :span="22">
              <el-form-item label="转出账户" prop="outAccountId" label-width="110px">
                <el-select v-model="ruleForm.outAccountId" placeholder="请选择转出账户" clearable filterable>
                  <el-option
                      v-for="item in accountArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="outAccountOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="转账金额" prop="transferMoney" label-width="110px">
                <el-input v-model="ruleForm.transferMoney"
                          placeholder="请填写转账金额"
                          :rules="[{ required: true, message: '请填写转账金额' }]"
                          clearable
                          style="width: 217px"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="转入账户" prop="inAccountId" label-width="110px">
                <el-select v-model="ruleForm.inAccountId" placeholder="请选择转入账户" clearable filterable>
                  <el-option
                      v-for="item in accountArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value"
                      @click.native="inAccountOnConfirm(item)"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
<!--              <el-form-item label="转账目的" prop="purpose" label-width="110px">-->
              <el-form-item label="转账目的" prop="purpose" label-width="110px">
                <el-input
                    type="textarea"
                    style="width: 217px;"
                    :autosize="{ minRows: 4, maxRows: 4}"
                    placeholder="请输入转账目的"
                    v-model="ruleForm.purpose">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item label="到账时间" prop="transferTime" label-width="110px">
                <el-date-picker
                    v-model="ruleForm.transferTime"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    type="datetime"
                    placeholder="选择到账时间"
                    align="right"
                    :picker-options="pickerOptions">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="22">
              <el-form-item style="margin-left: 10px;">
                <el-button type="primary" @click="addBankTransfer('ruleForm')">转账</el-button>
              </el-form-item>
            </el-col>
          </el-row>

        </el-form>
      </el-col>
    </el-row>


    <el-row style="margin-top: 15px;padding-left: 10px">
      <h3>近期转账记录</h3>
      <el-table
          border
          max-height="635"
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :data="proceedsArray"
          style="width: 99%">
        <el-table-column prop="receivedDate" label="到账时间" sortable align="center"></el-table-column>
        <el-table-column prop="remark" label="备注" sortable align="center"></el-table-column>
        <el-table-column prop="proceedsName" label="收款项目" align="center"></el-table-column>
        <el-table-column prop="payee" label="收款人" align="center"></el-table-column>
        <el-table-column prop="receivedAmount" label="到账金额" align="center"></el-table-column>
        <el-table-column prop="receivedAccountName" label="到账账户" align="center"></el-table-column>
      </el-table>
    </el-row>
  </div>
</template>

<script>


export default {
  name: "bank-transfer",
  created() {
    this.pageInit()
  },
  data() {
    return {
      ruleForm:{
        outAccountId: "",
        outAccountName: "",
        transferMoney: "",
        inAccountId: "",
        inAccountName: "",
        purpose: "",
        transferTime: this.$dateUtils.getTimeStr('s'), //要置空吗？
      },

      rules: {
        outAccountId: [{required: true, message: '请选择转出账户', trigger: 'blur'}],
        transferMoney: [{required: true, message: '请输入转账金额', trigger: 'blur'}],
        inAccountId: [{required: true, message: '请选择转入账户', trigger: 'blur'}],
        purpose: [{required: true, message: '请输入转账目的', trigger: 'blur'}],
        transferTime: [{required: true, message: '请选择到账时间', trigger: 'blur'}],
      },

      accountArray: [],

      search: {
        proceedsName: "902", //902, "转账入"
        //ivory是搜这个模糊查询 "remark": "从", //同时加收款和支出的备注 的第一个字是 "从"
        remark: "转账目的",
        tenantCrop: localStorage.getItem("tenantCrop")
      },
      proceedsArray: [],

      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            picker.$emit('pick', new Date());
          }
        }, {
          text: '昨天',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24);
            picker.$emit('pick', date);
          }
        }, {
          text: '一周前',
          onClick(picker) {
            const date = new Date();
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', date);
          }
        }]
      },
    }
  },
  methods: {

    addBankTransfer(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          if (this.ruleForm.transferMoney <= 0) {
            this.$alert('转账金额应该是正数!', '', {
              type: "warning",
              confirmButtonText: '确定',
            });
            return false
          }

          if (this.ruleForm.outAccountId == this.ruleForm.inAccountId) {
            this.$alert('转出账户和转入账户不能相同!', '', {
              type: "warning",
              confirmButtonText: '确定',
            });
            return false
          }

          let transferRemark = "转账目的: " + this.ruleForm.purpose
                              + "。从"+ this.ruleForm.outAccountName
                              + "转" + this.ruleForm.transferMoney
                              + "元到" + this.ruleForm.inAccountName + "。";

          //下面的message 居然识别不了换行
          /*
          // this.$confirm("到账时间：" + this.ruleForm.transferTime
          //     + '<br><br>转出账户：'+ this.ruleForm.outAccountName
          //     + '<br><br>转账金额：'+ this.ruleForm.transferMoney
          //     + '<br><br>转入账户：'+ this.ruleForm.inAccountName
          //     + '<br><br>转账目的：'+ this.ruleForm.transferRemark
          //     + '<br><br>确定转账吗？',
          //     '转账:', {
          //       confirmButtonText: '确定',
          //       cancelButtonText: '取消',
          * */


          this.$confirm("确定信息无误？",'转账:', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }).then(() =>
          {

            this.$axios(
                {
                  method: "POST",
                  url: "/account/addBankTransfer",
                  data: {
                    'outAccountId': this.ruleForm.outAccountId,
                    'transferMoney': this.ruleForm.transferMoney,
                    'inAccountId': this.ruleForm.inAccountId,
                    'transferRemark': transferRemark, //备注要处理得详细点
                    'transferTime': this.ruleForm.transferTime,
                    'empId': JSON.parse(localStorage.getItem('emp')).id,
                    'tenantCrop': localStorage.getItem("tenantCrop"),
                  },
                }).then(response => {

              if (response.data.code === 200)
              {
                this.$message.success("转账添加成功!")
                let self = this;
                setTimeout(function () {
                  // 刷新当前页面
                  location.reload();
                  // self.close()
                }, 1500)
              } else {
                this.$message.error(response.data.msg)
              }

              // if (flag) { //后端处理成功才刷新页面。 最好直接刷新页面的。。
              //   this.pageInit()
              //   this.reset('form')
              // }
            })


          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消'
            });
          });

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },


    pageInit() {
      /* 查公司结算方式参数 */
      this.$selectUtils.queryAccountIds().then(response => {
        this.accountArray = JSON.parse(response.data.data)
      })

      this.queryProceedsList();
    },

    //下拉框项目选中触发的函数。
    outAccountOnConfirm: function (value) {
      this.ruleForm.outAccountId = value.value;
      this.ruleForm.outAccountName = value.name;
    },
    inAccountOnConfirm: function (value) {
      this.ruleForm.inAccountId = value.value;
      this.ruleForm.inAccountName = value.name;
    },

    //级联选择关闭
    cascaderClsoe() {
      this.$refs.source.dropDownVisible = false;
    },

    queryProceedsList() {
      this.$axios({
        method: "get",
        url: "/proceeds/proceedsList",
        params: {
          limit: 10,
          page: 1,
          ...this.search,
        }
      }).then(response => {
        this.proceedsArray = response.data.data.list
        this.proceedsArray.sort((a, b) => {
          // 将 receivedDate 转换为时间戳进行比较
          const dateA = new Date(a.receivedDate).getTime();
          const dateB = new Date(b.receivedDate).getTime();

          // 根据 receivedDate 降序排序
          return dateB - dateA;
        });
      })
    },
  },


}
</script>

<style scoped>
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 148px;
  height: 148px;
  line-height: 148px;
  text-align: center;
}

.avatar {
  width: 148px;
  height: 148px;
  display: block;
  border: #8c939d;
}
</style>